body, html {
  margin: 0;
  padding: 0;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  border-radius: 30px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  width: 750px;
  margin: 0;
  position: relative; /* To position the search icon absolutely within */
  padding-right: 50px;
}

.search-input {
  flex: 3;
  font-family: inherit;
  height: 30px;
  border: none;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  outline: none;
  border-radius: 30px; /* Match the border radius to search bar */
  transition: background-color 0.3s; /* Smooth transition for background color */
  overflow: hidden;
  text-align: center;
}

.search-input::placeholder {
  text-align: center;
  color: #999; /* Custom placeholder color */
}

.search-input.small-width {
  flex: 2;
  -webkit-appearance: none; /* This is important for iOS */
  -moz-appearance: textfield;
  text-align: center;
}

/* Webkit browsers specific style */
.search-input.small-width::-webkit-inner-spin-button,
.search-input.small-width::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.search-input.small-width {
  -moz-appearance: textfield;
}

/* For IE11 */
.search-input.small-width::-ms-clear {
  display: none;
}

.search-input:hover {
  background-color: #dcdcdc; /* Slight grey out effect */
}

.search-button {
  display: flex; /* Use flexbox for button content */
  justify-content: center; /* Center icon horizontally */
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 10px;
  border-radius: 100%;
  background: #ff8000;
  color: #FFF;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px; /* Positioned to the right inside the search bar */
}

/* Adjusting hover effect for the button separately */
.search-button:hover {
  background-color: #ffa94d; /* Slight darken effect */
}

.search-button:hover i {
  color: #e0e0e0;
}

/* To remove the rounded corners from inputs that are not first or last child */
.search-input:not(:first-child):not(:last-child) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* To apply rounded corners to the first input */
.search-input:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* To apply rounded corners to the last input */
.search-input:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.divider {
  background-color: black;
  opacity: 0.2;
  height: 30px;
  width: 2px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-search-bar {
  cursor: pointer;
}

/* Animation for newly added search bars */
@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.search-bar-container {
  animation: slideInDown 0.5s ease-out;
  margin-bottom: 10px; /* Adjust as needed to ensure bars appear adjacent */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slide-out {
  animation: slideOutUp 0.5s ease-out forwards;
}

.add-search-bar {
  background-color: #ff8000; /* Match the search button color */
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  right: 10px;
}

/* For Webkit browsers like Chrome and Safari */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* CSS for the minus icon */
.remove-search-bar {
  background-color: #ff8000; /* Match the search button color */
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  right: 10px;
}

/* Ensure the remove button does not affect the search bar layout */
.search-bar {
  padding-right: 80px; /* Make room for the remove button */
}

/* Adjustments for the first search bar */
.search-bar:first-of-type .remove-search-bar {
  display: none; /* Hide remove button for the first search bar */
}

.DateRangePickerInput .DateInput_input {
  text-align: center;
  border: none !important;
  font-size: 16px;
  color: black;
}

/* Style the date range display area */
.DateRangePickerInput {
  /* Your custom styles for the date range display area */
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .search-bar {
    width: 480px; /* Adjust width as needed */
  }
}


/* Responsive media queries */
@media (max-width: 600px) {
  .search-bar {
    flex-direction: column; /* Stack inputs vertically on small screens */
    width: 85vw;
    padding-right: 0px; /* Remove padding-right as it's no longer needed */
  }

  .add-search-bar {
    background-color: #ff8000; /* Match the search button color */
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: inherit;
    right: 1vw;
  }

  .remove-search-bar {
    background-color: #ff8000; /* Match the search button color */
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: inherit;
    right: 1vw;
  }

  .search-input {
    height: 20px;
    font-size: 14px;
    margin: 0; /* Adjust margin for vertical stacking */
    padding: 0;
    width: 80%;
    align-items: center;
  }

  .search-button {
    width: 30px;
    height: 30px;
    right: 5px;
  }

  .divider {
    height: 0vw;
    width: 0vw;
  }

  .search-bar {
    /* existing styles */
    padding: 10px 30px 10px 10px; /* Adjust padding */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
  }

  .DateRangePicker_picker {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .DateInput_fang {
    display: none !important;
  }
  
  .DateRangePickerInput {
    width: 200px;
    height: 30px; /* Adjust as needed */
    padding: 0 0px; /* Adjust as needed */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .DateRangePickerInput .DateInput {
    width: 80px;
    padding: 0px 10px; /* Adjust as needed */
  }
  
  .DateRangePickerInput .DateInput_input {
    font-size: 14px; /* Adjust as needed */
    padding: 0px; /* Adjust as needed */
  }
  
}


/* Style the placeholder text in the input fields */
.DateInput_input::placeholder {
  color: #999; /* Custom placeholder color */
}



.DateRangePickerInput_arrow {
  display: none !important;
}

.search-input.DateInput_input:hover {
  color: #999;
}

.search-input.DateInput_input::placeholder:hover {
  color: #999;
}

/* Hover effect for date input fields */
.DateRangePickerInput .DateInput_input:hover {
  cursor: pointer; /* Change the cursor to indicate it's clickable */
  /* add hover effect HERE */
}

