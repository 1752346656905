/* Header.css */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #fff; /* Set your desired background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer; /* Indicate it's clickable */
  transition: transform 0.3s, color 0.3s;
}

.logo-container:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  color: #FF8000; /* Change text color on hover */
}

.app-title {
  font-size: 1.5rem; /* Adjust font size as needed */
  margin: 0;
}

.nav-links, .auth-links {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.nav-link {
  text-decoration: none;
  color: #333; /* Set your desired text color */
  margin-left: 10px;
  transition: font-size 0.3s; 
}
.nav-link.popular:hover {
  font-size: 20px;
}
.nav-link.home:hover {
  font-size: 20px;
}
.nav-link.login:hover {
  font-size: 20px;
}

.nav-link.signup {
  background-color: #ff8000; /* Set the background color for the Login link */
  color: #fff; /* Set the text color for the Login link */
  padding: 8px 15px; /* Adjust padding as needed */
  border-radius: 4px; /* Add rounded edges */
}
.nav-link.signup:hover {
  background-color: #ffa94d;
}
.search-container {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
}

.search-icon {
  padding: 9px;
  border-radius: 100%;
  background: #ff8000;
  color: #ffffff; /* Set the color of the icon */
  transition: background-color 0.3s, color 0.3s;  /* Add transition for smooth color change */
}

.search-icon:hover{
  background-color: #ffa94d; /* Change color to white on hover */
}

.top-bar-middle{
  display: flex;
  align-items: center;
}

.search-input:hover {
  background-color: transparent; /* Prevent color change on hover */
}
