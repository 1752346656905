/* SignInPopup.css */

.signin-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of everything */
  text-align: center;
}

.signin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  backdrop-filter: blur(3px); /* Apply blur effect */
}

.signin-popup {
  background-color: #fff;
  padding: 100px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1000; /* Ensure the popup is on top of the overlay */
}

.signin-popup h2 {
  margin-top: 0;
  margin-bottom: 20;
}

.signin-popup input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px; /* Round the edges of input fields */
  outline: none; /* Remove default input focus outline */
}

.signin-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signin-options button {
  padding: 10px 40px; /* Adjust button padding */
  margin: 5px 0; /* Add margin between buttons */
  border: none;
  border-radius: 20px; /* Round the edges of buttons */
  cursor: pointer;
}

.submit-button {
  background-color: #ff8000; /* Set sign-in button color */
  color: #fff; /* Set text color */
  font-weight: bold;
}

.submit-button:hover {
  background-color: #ffa94d;
}

.forgot-password {
  background-color: transparent;
  color: #000000;
  font-weight: bold;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #333;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-btn:hover {
  color: #4b4848;
}

/* New styles for mobile */
.signin-popup.mobile {
  padding: 10vw; /* Use viewport width for padding */
}

.signin-popup.mobile h2 {
  margin-bottom: 5vw; /* Use viewport width for margin */
}

.signin-popup.mobile input {
  margin-bottom: 3vw; /* Use viewport width for margin */
}

.signin-options.mobile {
  flex-direction: column; /* Change direction for mobile */
}

.signin-options.mobile button {
  width: 100%; /* Set button width for mobile */
  margin-bottom: 3vw; /* Use viewport width for margin */
  font-size: 4vw; /* Adjust font size for button */

}