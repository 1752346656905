/* Footer.css */

.footer {
    background-color: #fff;
    color: #333;
    text-align: center;
    padding: 10px 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  